<template>
  <div class="friends-wrap">
    <BackTop></BackTop>
    <div class="friends-list-wrap">
      <div v-if="loading">
        <van-skeleton title avatar :row="5" />
      </div>
      <van-pull-refresh
        v-else
        v-model="refreshing"
        @refresh="onRefresh"
        loading-text=" "
        loosing-text=" "
        pulling-text=" "
        class="refresh"
      >
        <van-list
          v-model="isLoading"
          :offset="0"
          loading-text=" "
          :finished="finished"
          @load="onReachBottom"
        >
          <div
            class="friends-item"
            v-for="(item, index) in friendsList"
            :key="index"
          >
            <div class="avator-name-box">
              <div class="avator-box">
                <img src="../../static/img/home/avatar.png" alt="" />
              </div>
              <div class="user-name">{{ item.address }}</div>
              <div class="user-status" style="color: #977ff1">
              {{  $t('myFriends.friend') }}
            </div>
            </div>
          </div>
        </van-list>
        <none-data v-if="!friendsList || friendsList.length <= 0"></none-data>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import BackTop from '@/components/common/BackTop.vue'

import { queryMyFriendsList } from '@/services/twins'
import NoneData from '../../components/common/NoneData.vue'
export default {
  components: { NoneData, BackTop },
  data () {
    return {
      finished: true, // 加载结束
      refreshing: false, // 下拉刷新状态
      isLoading: false, // 下拉刷新状态
      pageNo: 1,
      pageSize: 10,
      loading: true,
      total: 0,
      friendsList: [],
      loadingType: null
    }
  },
  methods: {
    async getMyFriendsList () {
      const resp = await queryMyFriendsList({
        pageNumber: this.pageNo,
        pageSize: this.pageSize
      }, this.loadingType)
      this.loading = false
      this.isLoading = false
      if (!resp.success) {
        this.finished = true
        return
      }
      this.total = resp.result.total
      if (this.pageNo >= resp.result.pages) {
        this.finished = true
      } else {
        this.finished = false
      }
      resp.result.records.map((item) => {
        item.world = parseInt(item.world)
        item.createTime = item.createTime.split(' ')[0]
        item.level = parseInt(item.level)
        item.world = parseInt(item.world)
        item.helpValue = item.helpValue
          ? this.$gbUtils.formatTwoBalance(item.helpValue)
          : item.helpValue
        item.forceValue = item.forceValue
          ? this.$gbUtils.formatTwoBalance(item.forceValue)
          : item.forceValue
      })
      this.friendsList.push(...resp.result.records)
    },
    onRefresh () {
      this.loading = true
      this.isLoading = true
      this.pageNo = 1
      this.friendsList = []
      this.loadingType = ''
      this.getMyFriendsList()
      this.refreshing = false
      this.loading = false
    },
    onReachBottom () {
      this.loadingType = 2
      this.isLoading = true
      this.pageNo++
      this.getMyFriendsList()
    }
  },
  computed: {
    forceValue () {
      return this.$t('myFriends.forceValue')
    },
    numberFriends () {
      return this.$t('myFriends.numberFriends')
    },
    powerValue () {
      return this.$t('myFriends.powerValue')
    },
    level () {
      return this.$t('home.level')
    }
  },
  mounted () {
    this.getMyFriendsList()
  }
}
</script>

<style lang="scss">
.friends-wrap {
  padding: 10px;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: #f8f8f8;
  overflow: hidden;
  .refresh {
    min-height: 100%;
  }
  .friends-list-wrap {
    background-color: #fff;
    border-radius: 10px;
    // min-height: 100vh;
    height: 100%;
    margin-bottom: 5px;
    overflow: scroll;
    .friends-item {
      width: 90%;
      margin: 0 auto;
      background-size: 100% 100%;
      padding: 10px;
      margin-bottom: 5px;
      position: relative;
      border-bottom: 1px solid #f0f0f0;
      .user-status {
        position: absolute;
        top: 10;
        right: 15px;
        font-size: 12px;
        font-weight: 400;
        color: #977ff1;
      }
      .avator-name-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .avator-box {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .user-name {
          font-size: 15px;
          font-weight: 700;
          color: #16172a;
          line-height: 22px;
        }
      }
      .value-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px !important;
        line-height: 1.5em;
        width: 150%;
        .power-value {
          display: flex;
          padding-right: 10px;
        }
        .value-title {
          font-size: 13px;
          font-weight: 400;
          padding-right: 9px;
        }
        .value-box {
          display: flex;
          flex: 1;
          align-items: center;

          .value {
            font-size: 13px;
            font-weight: 400;
            padding-right: 10px;
          }
          .friends-number {
            font-size: 13px;
            font-weight: 400;
            margin-right: 9px;
          }
        }
        .time {
          font-size: 12px;
          font-weight: 400;
        }
        .rank {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
